import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import NoSsr from "@mui/material/NoSsr";
import { Link } from "gatsby";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUserData } from "../state/user-context";

import ClientOnly from "./ClientOnly";
import LogoHomeIcon from "./LogoHomeIcon";

function Home() {
  const { state } = useUserData();

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  const responsiveButton = {
    fullWidth: isSmallScreen,
  };

  const guestCallToAction: React.ReactElement[] = [
    <Grid xs={12} sm={3} md={2} xl={1} textAlign="center">
      <Button
        variant="outlined"
        size="large"
        component={Link}
        to="/login"
        {...responsiveButton}
      >
        Login
      </Button>
    </Grid>,
    <Grid xs={12} sm={3} md={2} xl={1} textAlign="center">
      <Button
        variant="contained"
        size="large"
        component={Link}
        to="/signup"
        disabled
        {...responsiveButton}
      >
        Register
      </Button>
    </Grid>,
  ];

  const authenticatedCallToAction: React.ReactElement = (
    <Grid xs={12} textAlign="center">
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/app"
        {...responsiveButton}
      >
        Go to applications
      </Button>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={3}
      sx={{ backgroundColor: "secondary.light" }}
      padding="2rem"
      alignItems="center"
      justifyContent="center"
      color="secondary.contrastText"
    >
      <Grid xs={12}>
        {/* TODO _ fix this, should be a grid... */}
        <Box width="100%" justifyContent="center" display="flex">
          {!isSmallScreen && <LogoHomeIcon height="150px" width="150px" />}
          <Typography
            variant="h2"
            textAlign="center"
            fontWeight={700}
            display="inline"
            alignSelf="center"
          >
            New Soulcial Media
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12}>
        <Typography variant="h4" className="hero-subtext" textAlign="center">
          Internet for your soul
        </Typography>
      </Grid>
      <ClientOnly transition="fade">
        {state.isAuthenticated ? authenticatedCallToAction : guestCallToAction}
      </ClientOnly>
    </Grid>
  );
}

export default Home;
