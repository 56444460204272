import * as React from "react";
import { HeadFC } from "gatsby";
import Home from "../components/Home";

const IndexPage = () => {
  return <Home />;
};

export default IndexPage;

export const Head: HeadFC = () => <title>Home Page</title>;
